<template>
  <div v-if="dialog && users">
    <v-dialog :value="dialog" max-width="50%" persistent transition="dialog-bottom-transition">
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('onCloseDialog')">
            <i class="fas fa-lg fa-window-close"></i>
          </v-btn>
          <v-toolbar-title class="text-uppercase">
            {{ $t('title.user_list') }} 4
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
              v-model="dataRequest.search"
              append-icon="mdi-magnify"
              :label="$t('input.search')"
              single-line
              clearable
              @input="$emit('getData4')"
          />
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="_id"
              :headers="headers"
              :footer-props="footerProps"
              :items="users ? users.object : []"
              :server-items-length="users ? users.objectCount : null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              @update:page="$emit('getData4')"
              @update:items-per-page="$emit('getData4')"
              @update:sort-desc="$emit('getData4')"
              mobile-breakpoint="0"
          >
            <template v-slot:[`item.enable`]="{ item }">
                 <v-btn :color="item.enable ? 'success' : 'error'" @click="_openDialogSuspend(item)" :disabled="userp && !userp.enable? true:false">
            {{ item.enable ? "Active" : "Inactive" }}
            </v-btn>
            </template>
                  <template v-slot:[`item.balance`]="{ item }">
                  <span   v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && (item.currency_type===1 || item.currency_type===3) " >
                  {{ currencyFormatUsd(item.balance)}}
                </span>
                <span   v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && item.currency_type===2" >
                  {{ currencyFormatKh(item.balance)}}
                </span>
                <span   v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && item.currency_type===4" >
                  {{ currencyFormatKh(item.balance)}}
                </span>
                <span   v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && item.currency_type===5" >
                  {{ currencyFormatKh(item.balance)}}
                </span>
              
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="green"
                      small class="mr-2"
                      @click="getUserChild5(item)"
                      v-if="item.objChild > 0"
              >mdi-eye
              </v-icon>
              <v-icon color="green"
                      small class="mr-2"
                      disabled
                      v-else
              >mdi-eye-off
              </v-icon>
            </template>
            <template v-slot:[`item.updateConfigUser`]="{ item }">
              <v-btn  v-if="item.role_id !='61829df5e64b1b1768832700' && item.role_id !='61829dfee64b1b1768832709' && item.role_id !='61829e05e64b1b1768832712' && item.role_id !='6424075250b77c6cf890ab0e' " color="primary" @click="getAllLimitBetting(item)" :disabled="userp && !userp.enable? true:false">
                <v-icon>mdi-cogs</v-icon> 
              </v-btn>
              <v-btn  v-if="item.role_id =='61829df5e64b1b1768832700' || item.role_id =='61829dfee64b1b1768832709' || item.role_id =='61829e05e64b1b1768832712' || item.role_id =='6424075250b77c6cf890ab0e' " color="warning" @click="upConfigUser(item)" :disabled="userp && !userp.enable? true:false">
                <v-icon>mdi-cogs</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <user-list5
        :darkmode="darkmode"
        :dialog="dialogListUser"
        :users="userChild5"
        :data-request="data5"
        v-on:getData5="getUserChild5(item)"
        @onCloseDialog="dialogListUser = !dialogListUser"
    />
        <v-dialog v-model="suspendDialog" persistent max-width="500px">
      <v-card >
        <v-card-title class="text-h5 py-5">
         <!-- {{ targetChannel && targetChannel.enable ?  'បិទដំណើរការ Channel ' : 'បើកដំណើរការ Channel '}}
          -->
          ok ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="blue-grey"    class="ma-2 white--text"  @click="_closeDialogSuspend">Cancel</v-btn>
          <v-spacer></v-spacer>
          <!-- <v-btn     class="ma-2 white--text" :color="targetChannel && targetChannel.enable ?  'error':'success'" @click="_takeAction">{{targetChannel && targetChannel.enable ? "Inactive Channel" :"Active Channel"}}</v-btn> -->
          <v-btn  class="ma-2 white--text" :color="selectedUser && selectedUser.enable ?  'error':'success'"
          @click="_updateUserSuspendStatus()">{{selectedUser && selectedUser.enable ? "Inactive Channel" :"Active Channel"}}</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
      <loader
      v-if="this.waiting == true"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
    <config-user-data
      :darkmode="darkmode"
      :dialog="dialogConfigUserData"
      :dataConfig="configUserData"
      :loading="loading"
      :limitConfig="limitConfig"
      v-on:submit="onChangeConfigUser"
      v-on:onCloseDialog="closeConfigUserDialog"
    />

    <config-high-level-user
      :darkmode="darkmode"
      :dialog="dialogConfigHighLevelUser"
      :dataConfig="limitHighLevelUser"
      :limitConfig="limitConfig"
      :loading="loading"
      v-on:submit="onChangeConfigHighLevelUser"
      v-on:onCloseDialog="closeConfigHighLevelUserDialog"
    />
  </div>
</template>

<script>
import UserList5 from "./Dialog5";
import configUserData from "../ConfigUserData.vue";
import ConfigHighLevelUser from "../ConfigHighLevelUser";
import { mapActions } from "vuex";

export default {
  components: {UserList5, configUserData, ConfigHighLevelUser},
  props: ["darkmode", "dialog", "users", "dataRequest","userp"],
  data: () => {
    return {
      dialogListUser: false,
      userChild5: [],
      footerProps: {
        'items-per-page-options': [10, 20, 50, 100, 200]
      },
      item: null,
      data5: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ['_id'],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
        search: "",
        parentId: null
      },
      selectedUser:[],
    suspendDialog : false,
    waiting:false,
    limitConfig: {},
      configUserData: [],
      limitHighLevelUser: [],
      configHighLevelUserCompany: {},
      dialogConfigHighLevelUserCompany:false,
      loading:false,
      dialogConfigUserData: false,
      dialogConfigHighLevelUser: false,
      channel_type: 1
    }
  },
  computed: {
    headers() {
      return [
      { text: this.$t('header.actions'), value: 'actions', sortable: false },
        {text: this.$t('header.user_name'), align: 'start', sortable: false, value: 'user_name'},
        { text: this.$t('header.bank_account'), value: 'account_info' },
        { text: this.$t('header.balance'), value: 'balance' },
        { text: this.$t('header.role'), value: 'role_name' },
        { text: this.$t('header.created_at'), value: 'updatedAt' },
        { text: this.$t('header.updateConfig'), value: 'updateConfigUser' },
        { text: this.$t('header.active'), value: 'enable' }
      ];
    },
  },
  methods: {
      currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
    },
    getUserChild5(item) {
      this.item = item
      this.data5.parentId = this.item._id
      this.data5.sortByFormat.length < 1 ? this.data5.sortBy = '_id' : this.data5.sortBy = this.data5.sortByFormat[0]
      this.data5.descendingFormat.length > 0 ? this.data5.descending = this.data5.descendingFormat[0] : this.data5.descending = true
      this.$request.post({
        url: 'users/getAllByUserId',
        data: this.data5
      }).then((res) => {
        if (res.data.code) {
          this.dialogListUser = true
          this.userChild5 = res.data.data
        }
      })
    },
    _openDialogSuspend(item){
      this.selectedUser = item
      this.suspendDialog = true
      
    },
     _closeDialogSuspend(){
      this.selectedUser = []
      this.suspendDialog = false
    },
    async _updateUserSuspendStatus(){
      this.waiting = true;
      let data = {
        enable : !this.selectedUser.enable,
        userId: this.selectedUser._id// channel_type : this.channel_type
      }
      await this.$request
        .post({
          url: "/users/suspendUserTreeDown",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
           this.$emit('getData4')
            this._closeDialogSuspend()
            this.waiting = false;
          }else{
            this.$emit('getData4')
            this._closeDialogSuspend()
            this.waiting = false;
            
          }
        })},
        _openDailogConfig(item){
      let data = {user_id: item._id };
      this.$request
        .post({
          url: "limitBetting/getCompanyLimitBetting",
          data: data,
        })
        .then((res) => {
          this.configHighLevelUserCompany = res.data.data;
          this.dialogConfigHighLevelUserCompany = true;
        });
    },
    async getAllLimitBetting(data) {
      this.item = data;
      this.$request
        .post({
          url: "limitBetting/getAll",
          data: data,
        })
        .then((res) => {
          this.limitHighLevelUser = res.data.data.object;
          this.channel_type = this.limitHighLevelUser[0] ?this.limitHighLevelUser[0].channel_type : 1;
          this.getLimitData(data, this.channel_type);
          this.dialogConfigHighLevelUser = true;
        });
    },
    async onChangeTab(channel_type){
      await this.getLimitData(this.item,channel_type);
    },
    async getLimitData(item, channel_type) {
      let data = {
        currency_type: item.currency_type,
        _id: item.parent_id,
        channel_type: channel_type
      };
      const response = await this.$request.post({
        url: "limitBetting/getParentLimitBettingByChannel",
        data: data,
      });
      this.limitConfig = response.data.data.object;
    },
    async onChangeConfigHighLevelUser(payload) {
      this.loading = true;
      let formatData = this.formatHighLevelUserData(payload);
      if (formatData._id) {
        await this.updateLimitSetting({
          vm: this,
          data: formatData,
        });
        this.loading = false;
        //this.dialogConfigHighLevelUser = false;
      }
    },
    closeConfigHighLevelUserDialog() {
      this.dialogConfigHighLevelUser = false;
    },

    async onChangeConfigUser(payload) {
      this.loading = true;
      let dataLimit = this.configUserData.filter(channel => channel.channel_type == payload);
      let data;
      if (dataLimit.length > 0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
        }
      }else{
        data = this.configUserData;
      }
      if (data._id) {
        await this.updateConfigUser({ vm: this, d: data });
        this.loading = false;
        // this.dialogConfigUserData = false;
      }
      this.$emit('getData4')
    },
    formatHighLevelUserData(payload){
      let dataLimit = this.limitHighLevelUser.filter(channel => channel.channel_type == payload);
      let data;
      if(dataLimit.length >0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
        };
      }else{
        data = this.limitHighLevelUser;
      }
      data.currency_type = this.item.currency_type;
      return data;
    },

    async upConfigUser(data) {
      this.$request
        .post({
          url: "configUser/getConfigData",
          data: data,
        })
        .then((res) => {
          this.configUserData = res.data.data.object;
          this.channel_type = this.configUserData[0] ?this.configUserData[0].channel_type : 1;
          this.getLimitData(data, this.channel_type);
          this.dialogConfigUserData = true;
        });
      
    },
    closeConfigUserDialog() {
      this.dialogConfigUserData = false;
    },
    ...mapActions("$_configUser", [
      "updateLimitSetting",
    ]),
    ...mapActions("$_user", [
      "updateConfigUser",
    ]),
  }
}
</script>

<style scoped>

</style>
